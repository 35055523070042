import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams.start_param) {
      const parts = route.queryParams.start_param.split('-');
      if (parts.length === 2) {
        const routePath = `/${parts[0]}/${parts[1]}`;
        this.router.navigate([routePath], {
          queryParams: {},
        });
      }
    }

    if (route.queryParams.token != undefined && route.queryParams.expires != undefined) {
      this.authService.setAuthDataFromUrl(route.queryParams.token, route.queryParams.expires);

      return new Promise((resolve) => {
        this.authService.getUserByToken().subscribe({
          next: () => {
            // удаляем токен из адресной строки если это не мобильник
            if (!this.deviceService.isMobile()) {
              this.router.navigate([], {
                queryParams: { token: null, expires: null },
                queryParamsHandling: 'merge',
              });
            }
            resolve(this.handle());
          },
          error: (error) => {
            if (error?.status === 401) {
              this.authService.logout();
              resolve(false);
            } else {
              console.error('Ошибка при получении данных пользователя:', error);
              resolve(true);
            }
          },
        });
      });
    } else {
      return this.handle();
    }
  }

  handle(): Promise<boolean | UrlTree> {
    const currentUser = this.authService.currentUserValue;

    // Проверяем наличие токена, а не только currentUser
    const hasToken = this.authService.getAuthToken();

    if (currentUser || hasToken) {
      // Если есть currentUser или хотя бы валидный токен - разрешаем доступ
      return Promise.resolve(true);
    }

    // Делаем logout только если нет ни пользователя, ни токена
    this.authService.logout();
    return Promise.resolve(false);
  }
}
