import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { map, catchError, finalize, takeUntil } from 'rxjs/operators';
import { UserModel, AuthModel } from '../models';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileService } from '@app/pages/profile/profile.service';

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private readonly destroy$ = new Subject<void>();
  private readonly AUTH_TOKEN_KEY = 'authToken' as const;

  // public fields
  readonly currentUser$: Observable<UserType>;
  readonly isLoading$: Observable<boolean>;
  readonly currentUserSubject = new BehaviorSubject<UserType>(undefined);
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  constructor(
    private readonly router: Router,
    private readonly tokenService: TokenService,
    private readonly profileService: ProfileService
  ) {
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  setAuthDataFromUrl(token: string, expires: string): void {
    const authData = new AuthModel();
    authData.authToken = token;
    authData.expires = new Date(expires);
    this.tokenService.setAuthData(authData);
  }

  logout(): void {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    this.currentUserSubject.next(undefined);
    void this.router.navigateByUrl('/auth/login');
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.tokenService.authData;
    if (!auth?.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.profileService.getProfileData().pipe(
      takeUntil(this.destroy$),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this.logout();
      return throwError(() => new Error('Unauthorized: Invalid authentication'));
    }

    const errorMessage =
      error.status === 0 ? 'Network error occurred' : `Server error: ${error.status}`;

    console.error(errorMessage, error);
    return throwError(() => new Error(errorMessage));
  }

  getAuthToken(): string | null {
    return localStorage.getItem(this.AUTH_TOKEN_KEY);
  }

  getCurrentUserId(): number | undefined {
    return this.currentUserValue?.id;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
