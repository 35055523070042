import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, UserType } from '@core/auth';
import { Observable, Subject } from 'rxjs';
import { Metrika } from 'ng-yandex-metrika';
import { takeUntil } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly REFERRER_URL = 'https://seller.wildberries.ru/';
  private readonly REFERRER_KEY = 'wildberriesReferrer';
  private readonly REFERRER_EXPIRATION_TIME = 5 * 60 * 1000; // 5 минут
  private readonly destroy$ = new Subject<void>();

  user$: Observable<UserType>;

  constructor(
    private readonly auth: AuthService,
    private readonly ym: Metrika
  ) {
    this.user$ = this.auth.currentUserSubject.asObservable();
  }

  ngOnInit(): void {
    this.handleDomainRedirect();
    this.setupUserTracking();
    this.handleReferrer();
    this.saveUTMToLocalStorage();
  }

  private handleDomainRedirect(): void {
    const oldDomain = 'seller.indigolab.io';
    const newDomain = 'seller.ilai.io';
    if (window.location.hostname === oldDomain) {
      window.location.href = window.location.href.replace(oldDomain, newDomain);
    }
  }

  private setupUserTracking(): void {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user?.id) {
        const userId = String(user.id);
        this.ym.setUserID(userId);
        this.ym.userParams({ UserID: user.id });
      }
    });
  }

  private handleReferrer(): void {
    if (document.referrer !== this.REFERRER_URL) {
      return;
    }

    const expirationTime = new Date().getTime() + this.REFERRER_EXPIRATION_TIME;
    const referrerData = {
      referrer: document.referrer,
      expiration: expirationTime,
    };

    localStorage.setItem(this.REFERRER_KEY, JSON.stringify(referrerData));
    this.scheduleReferrerCleanup();
  }

  private scheduleReferrerCleanup(): void {
    setTimeout(() => {
      const savedData = localStorage.getItem(this.REFERRER_KEY);
      if (!savedData) return;

      const { expiration } = JSON.parse(savedData);
      if (new Date().getTime() >= expiration) {
        localStorage.removeItem(this.REFERRER_KEY);
      }
    }, this.REFERRER_EXPIRATION_TIME);
  }

  private saveUTMToLocalStorage(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = Array.from(urlParams.entries())
      .filter(([key]) => key.startsWith('utm_'))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    if (Object.keys(utmParams).length > 0) {
      localStorage.setItem('utmParams', JSON.stringify(utmParams));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
